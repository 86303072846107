@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.App {
  overflow: hidden;
}

a {
  cursor: pointer;
  color: inherit;
}

img {
  width: 100%;
}

ul,
ol,
li {
  list-style: none;
}

body {
  font-family: "Inter", sans-serif;
  background-color: #000;
  color: #fff;
}

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  background: #000;
  height: 230px;

  margin: auto;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  //   width: 960px;
  border: 2px solid rgba(255, 255, 255, 0.16);
  border-radius: 30px;

  @media screen and (max-width: 768px) {
    height: 150px;
  }

  &::before,
  &::after {
    //  @include white-gradient;
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(250px * 14);
    gap: 30px;
  }

  .slide {
    display: flex;
    width: 250px;
    @media screen and (max-width: 768px) {
      width: 150px;
    }
  }
}

.text-gradient-1 {
  background: linear-gradient(270deg, #ff306e -4.95%, #994f25 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-2 {
  background: linear-gradient(
    270deg,
    #ff306e -4.03%,
    #fc1ab0 44.98%,
    #f900ff 102.9%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-3 {
  background: linear-gradient(90deg, #00ffd1 -42.01%, #e801df 139.19%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-4 {
  background: linear-gradient(
    270deg,
    #30c16a -4.51%,
    rgba(144, 29, 147, 0.72) 104.1%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
